<template>
  <v-row>
    <v-col
      cols="12"
      md="5"
    >
      <v-text-field
        v-model="computedDataParams.fullname"
        :label="label"
        dense
        outlined
        chips
        hide-details
        readonly
      ></v-text-field>
    </v-col>

    <v-col
      cols="12"
      md="2"
    >
      <v-text-field
        v-model.number="computedDataParams.final_amount"
        :label="$t('transactions.price')"
        class="text-body-1"
        :class="{ 'success--text': computedDataParams.paid === 'Y' }"
        outlined
        prefix="$"
        dense
        hide-details
        disabled
      >
        <template v-slot:append>
          <div v-if="!computedDataParams.enabled || disabledPrice || computedDataParams.paid === 'Y'">
            <span
              v-if="computedDataParams.payment_medium === 'online'"
              class="text-xs"
            >
              Online
            </span>
            <span
              v-if="computedDataParams.payment_medium === 'onsite_payment_method'"
              class="text-xs"
            >
              {{ computedDataParams.onsite_payment_method_name }}
            </span>
          </div>
        </template>
      </v-text-field>
    </v-col>

    <v-col
      cols="12"
      md="5"
      class="d-flex align-start"
      block
    >
      <v-btn
        color="primary"
        :style="$vuetify.breakpoint.smAndDown ? '' : 'max-width: 220px !important; min-width: 220px;'"
        disabled
      >
        {{ $t('reservation_detail.view_receipt') }}
      </v-btn>
    </v-col>
  </v-row>
</template>

<script>
import {
  ref, computed, watch, onMounted,
} from '@vue/composition-api'
import {
  getContactFacilitySearch, createContact, searchUserByPhoneEmail, addUserMatch, removeUserMatch,
} from '@api'
import {
  mdiDotsVertical, mdiPlusCircleOutline, mdiAccountOutline, mdiClose, mdiMenuDown,
} from '@mdi/js'
import { avatarText, title } from '@core/utils/filter'
import {
  required, integerValidator, regexValidator, charUnique, between, emailValidator,
} from '@core/utils/validation'
import { onlyNumberDot, onlyNumbers } from '@core/utils/functionsHelpers'
import useCountry from '@core/utils/useCountry'
import usePhone from '@core/utils/usePhone'
import { trimSpace } from '@core/utils'
import parsePhoneNumber from 'libphonenumber-js'
import { useUtils } from '@core/libs/i18n'

// import { success, error } from '@core/utils/toasted'

export default {
  props: {
    reservationId: {
      type: Number,
      default: 0,
      required: true,
    },
    reservationType: {
      type: String,
      default: '',
      required: false,
    },
    label: {
      type: String,
      default: '',
      required: false,
    },
    placeIndex: {
      type: Number,
      default: 0,
      required: true,
    },
    playerIndex: {
      type: Number,
      default: 0,
      required: true,
    },
    facility: {
      type: Number,
      default: 0,
      required: true,
    },
    reservationDataParams: {
      type: Object,
      default: () => ({
        reservation_id: null,
        recurring_id: null,
        match_id: null,
        field_id: null,
        field_name: null,
        sport_name: null,
        reservation_date: null,
        status: null,
        status_str: null,
        payment_status: null,
        payment_status_str: null,
        payment_type: null,
        reservation_type: null,
        reservation_time: null,
        reservation_time_int: null,
        start_time: null,
        end_time: null,
        video_id: null,
        video_thumbnail: null,
        include_video: null,
        time_str: null,
        price_per_player: null,
        reservation_price: null,
        video_price: null,
        others_products_price: null,
        balance: null,
        reservation_share_url: null,
        players: [],
        players_qty: null,
        players_qty_max: null,
        players_qty_min: null,
        cancel_deadline: null,
        payment_deadline: null,
        payment_medium: null,
        origin: null,
      }),
      required: true,
    },
    dataParams: {
      type: Object,
      default: () => ({
        id: null,
        user_match_id: null,
        contact_id: null,
        user_app_id: null,
        fullname: null,
        phone_number: null,
        email: null,
        final_amount: 0,
        paid: 'N',
        payment_medium: null,
        onsite_payment_method_id: null,
        onsite_payment_method_name: null,
        empty: true,
        enabled: true,
        is_organizer: 'N',
        avatar: null,
        checked: false,
        edited: false,
      }),
      required: true,
    },
    selectedParticipant: {
      type: Array,
      default: () => [],
      required: true,
    },
    onsitePaymentMethodsOptions: {
      type: Array,
      default: () => [],
      required: true,
    },
    disabledPrice: {
      type: Boolean,
      default: false,
      required: true,
    },
    initMode: {
      type: Boolean,
      default: false,
      required: false,
    },
    editMode: {
      type: Boolean,
      default: false,
      required: false,
    },
    perParticipantPrice: {
      type: Number,
      default: 0,
      required: true,
    },
    remainingAmount: {
      type: Number,
      default: 0,
      required: true,
    },
    isOpen: {
      type: Boolean,
      default: false,
      required: true,
    },
  },
  setup(props, { emit }) {
    const { t } = useUtils()
    const { getCountryCode } = useCountry()
    const { phoneCodeOptions } = usePhone()
    const computedDataParams = computed(() => props.dataParams)
    const computedInitMode = computed(() => props.initMode)
    const computedEditMode = computed(() => props.editMode)
    const computedIsOpen = computed(() => props.isOpen)
    const computedRecurringType = computed(() => props.reservationType)

    const participantsSearched = ref([])
    const contactSelectedId = ref(null)
    const searchQuery = ref(null)
    const searchLoading = ref(false)
    const timerSearch = ref(null)

    const showOnsitePayment = ref(false)
    const itemsMenu = ref([
      { title: 'Delete participant', value: 1 },
      { title: 'View receipt + Delete participant', value: 2 },
    ])
    const autoContact = ref(null)

    const isContactModalOpen = ref(false)
    const contactSelected = ref(null)
    const contactData = ref({
      fullname: null,
      phone_code: null,
      phone_prefix: null,
      phone_number: null,
      email: null,
      status: 'A',
      categories_ids: [],
    })
    const formContactDialog = ref(null)

    const initCode = ref('')
    const phoneCode = ref('')
    const phoneNumber = ref('')
    const searchedUser = ref(null)
    const searchedUserError = ref(null)
    const timer = ref(null)
    const timerRecalculatePayment = ref(null)

    const resolveParticipantLabel = computed(() => {
      let textLabel = ''
      if (props.placeIndex === 0 && ['F', 'flat'].includes(props.reservationDataParams.reservation_type)) textLabel = t('reservations.organizer')
      else if (computedDataParams.value.empty) textLabel = `${t('reservations.participant')} (${t('reservations.optional')})`
      else textLabel = `${t('reservations.participant')}`

      return textLabel.trim()
    })

    const recalculatePayment = async e => {
      if (timerRecalculatePayment.value) clearTimeout(timerRecalculatePayment.value)
      timerRecalculatePayment.value = setTimeout(async () => {
        if (Number.parseFloat(e) > Number.parseFloat(props.remainingAmount)) {
          computedDataParams.value.final_amount = Number.parseFloat(props.remainingAmount)
        }
        emit('recalculatePayment')
      }, 300)
    }

    const searchUser = async code => {
      if (timer.value) clearTimeout(timer.value)
      timer.value = setTimeout(async () => {
        searchedUserError.value = null
        let searchPhone = 'null'
        let searchEmail = 'null'

        if (contactData.value.phone_number) searchPhone = contactData.value.phone_number
        else if (phoneNumber.value) searchPhone = phoneNumber.value
        if (contactData.value.email) searchEmail = contactData.value.email

        if (!searchedUser.value) {
          const response = await searchUserByPhoneEmail(searchPhone, searchEmail)
          if (response.ok) {
            searchedUser.value = response.data
            contactData.value.user_app_id = response.data.username

            if (code === 'email') {
              const phoneNumberSearch = parsePhoneNumber(response.data.phone_number)
              phoneNumber.value = phoneNumberSearch.nationalNumber
              phoneCode.value = phoneNumberSearch.country

              contactData.value.phone_number = phoneNumberSearch.number
              contactData.value.phone_code = phoneNumberSearch.country
              contactData.value.phone_prefix = `+${phoneNumberSearch.countryCallingCode}`
            } else {
              contactData.value.email = response.data.email
            }
          } else {
            searchedUser.value = null
            contactData.value.user_app_id = null
          }
        } else {
          searchedUser.value = null
          contactData.value.user_app_id = null
        }
      }, 700)
    }

    const fetchContacts = async () => {
      if (searchQuery.value) {
        searchLoading.value = true
        const response = await getContactFacilitySearch(props.facility || 0, searchQuery.value)
        if (response.ok && response.data.length) {
          const newContact = [
            {
              id: 0,
              first_name: searchQuery.value,
              last_name: searchQuery.value,
              fullname: searchQuery.value,
              phone_number: searchQuery.value,
              email: searchQuery.value,
            },
          ]
          if (props.placeIndex > 0) {
            newContact.push({
              id: 1,
              first_name: searchQuery.value,
              last_name: searchQuery.value,
              fullname: searchQuery.value,
              phone_number: searchQuery.value,
              email: searchQuery.value,
            })
          }

          const filteredArr = response.data.filter(e => !props.selectedParticipant.includes(e.id))
          participantsSearched.value = newContact.concat(filteredArr)
        } else {
          participantsSearched.value = []
        }
        searchLoading.value = false
      }
    }

    const getDataBySearch = async () => {
      clearTimeout(timerSearch.value)
      timerSearch.value = setTimeout(async () => {
        await fetchContacts()
      }, 700)
    }

    watch([searchQuery], async () => {
      if (contactSelectedId.value) {
        const firSearch = searchQuery.value
        contactSelectedId.value = null
        setTimeout(() => {
          searchQuery.value = firSearch
        }, 50)
      }
      await getDataBySearch()
    })

    const onAddMatchParticipant = async body => {
      const resp = await addUserMatch(body)
      if (resp.ok) {
        // success(resp.message)
      } else {
        // error(resp.message.text)
      }

      return resp.ok === 1
    }

    const addGuest = async () => {
      let valid = true
      if (props.editMode) {
        const body = {
          user_id: null,
          match_id: props.reservationDataParams.match_id,
          onsite_payment_method_id: null,
          contact_id: null,
          is_organizer: 'N',
          paid: 'N',
          fullname: searchQuery.value ? searchQuery.value : `Player ${props.playerIndex + 1}`,
          phone_number: null,
          email: null,
        }
        valid = await onAddMatchParticipant(body)
      }

      if (valid) {
        const index = new Date().getTime()
        computedDataParams.value.empty = false
        computedDataParams.value.id = `guest-${index}`
        computedDataParams.value.fullname = searchQuery.value ? searchQuery.value : `Player ${props.playerIndex + 1}`
        participantsSearched.value.push({
          id: computedDataParams.value.id,
          facility_id: null,
          first_name: null,
          last_name: null,
          fullname: computedDataParams.value.fullname,
          phone_code: null,
          phone_prefix: null,
          phone_number: null,
          email: null,
          status: null,
          user_fullname: null,
          user_app_id: null,
          avatar: null,
        })

        emit('refreshDetails')
        emit('updateNextEnabled', props.placeIndex + 1)
        emit('addGuest', props.playerIndex + 1)

        searchQuery.value = null
      }
    }

    const addContact = () => {
      isContactModalOpen.value = true
      if (formContactDialog.value) formContactDialog.value.resetValidation()
      phoneCode.value = initCode.value
      if (searchQuery.value) contactData.value.fullname = searchQuery.value ?? ''
    }

    const onSelectContact = async val => {
      if (val && val > 1) {
        const finded = participantsSearched.value.find(e => e.id === val)
        if (props.editMode) {
          const body = {
            user_id: finded.user_app_id,
            match_id: props.reservationDataParams.match_id,
            onsite_payment_method_id: null,
            contact_id: finded.id,
            is_organizer: 'N',
            paid: 'N',
            fullname: finded.fullname,
            phone_number: finded.phone_number,
            email: finded.email,
          }
          await onAddMatchParticipant(body)
        }
        computedDataParams.value.empty = false
        computedDataParams.value.id = finded.id
        computedDataParams.value.contact_id = finded.id
        computedDataParams.value.user_app_id = finded.user_app_id
        computedDataParams.value.fullname = finded.fullname
        computedDataParams.value.phone_number = finded.phone_number
        computedDataParams.value.email = finded.email
        computedDataParams.value.email = finded.email
        computedDataParams.value.avatar = finded.avatar
        computedDataParams.value.final_amount = props.perParticipantPrice

        searchQuery.value = null

        emit('updateNextEnabled', props.placeIndex + 1)
      } else if (val === 0) {
        contactSelectedId.value = null
        addContact()
      } else if (val === 1) {
        addGuest()
      } else {
        contactSelectedId.value = null
        participantsSearched.value = []
        searchQuery.value = null
      }
    }

    const searchFilter = (item, queryText) => (item.fullname && item.fullname.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1)
      || (item.user_fullname && item.user_fullname.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1)
      || (item.phone_number && item.phone_number.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1)
      || (item.email && item.email.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1)

    const validatePhone = (e, code) => {
      if (code && e && /^([0-9])*$/.test(e)) {
        const phoneNumberJS = parsePhoneNumber(e, code)
        if (phoneNumberJS) {
          if (phoneNumberJS.isValid()) {
            contactData.value.phone_number = phoneNumberJS.number
            contactData.value.phone_code = phoneNumberJS.country
            contactData.value.phone_prefix = `+${phoneNumberJS.countryCallingCode}`

            return true
          }
          contactData.value.phone_number = null
          contactData.value.phone_code = null
          contactData.value.phone_prefix = null
        }
      }

      return 'Invalid number'
    }

    const onAddContact = async item => {
      if (props.editMode) {
        const body = {
          user_id: item.user_app_id,
          match_id: props.reservationDataParams.match_id,
          onsite_payment_method_id: null,
          contact_id: item.id,
          is_organizer: 'N',
          paid: 'N',
          fullname: item.fullname,
          phone_number: item.phone_number,
          email: item.email,
        }
        await onAddMatchParticipant(body)
      }

      computedDataParams.value.id = item.id
      computedDataParams.value.contact_id = item.id
      computedDataParams.value.user_app_id = item.user_app_id
      computedDataParams.value.user_match_id = item.user_match_id
      computedDataParams.value.fullname = item.fullname
      computedDataParams.value.phone_number = item.phone_number
      computedDataParams.value.email = item.email
      computedDataParams.value.final_amount = props.dataParams.final_amount
      computedDataParams.value.paid = 'N'
      computedDataParams.value.onsite_payment_method_id = null
      computedDataParams.value.empty = false

      participantsSearched.value.push({
        id: item.id,
        facility_id: item.id,
        first_name: null,
        last_name: null,
        fullname: item.fullname,
        phone_code: item.phone_code,
        phone_prefix: item.phone_prefix,
        phone_number: item.phone_number,
        email: item.email,
        status: item.status,
        user_fullname: null,
        user_app_id: item.user_app_id,
        avatar: null,
      })
      emit('updateNextEnabled', props.placeIndex + 1)
      isContactModalOpen.value = false
      if (formContactDialog.value) formContactDialog.value.reset()
    }

    const onCreateContact = async () => {
      if (formContactDialog.value.validate()) {
        const bodyContact = {
          fullname: contactData.value.fullname ? title(trimSpace(contactData.value.fullname)) : null,
          phone_code: contactData.value.phone_code,
          phone_prefix: contactData.value.phone_prefix,
          phone_number: contactData.value.phone_number,
          email: contactData.value.email,
          user_app_id: contactData.value.user_app_id,
          status: 'A',
          categories_ids: contactData.value.categories_ids,
          facility_id: props.facility,
        }
        const resp = await createContact(bodyContact)
        if (resp.ok) {
          bodyContact.id = resp.data.contact_id
          onAddContact(bodyContact)

          // success(resp.message)
        } else {
          // error(resp.message.text)
          searchedUserError.value = resp.message.text
        }
      }
    }

    const onCancelContact = () => {
      searchQuery.value = null
      searchedUserError.value = null
      searchedUser.value = null

      phoneCode.value = null
      phoneNumber.value = null
      contactData.value.fullname = null
      contactData.value.phone_number = null
      contactData.value.phone_code = null
      contactData.value.phone_prefix = null
      contactData.value.email = null
      contactData.value.status = 'A'
      contactData.value.categories_ids = []
      formContactDialog.value.resetValidation()

      const countryCode = getCountryCode()
      if (countryCode) {
        const codeFinded = phoneCodeOptions.value.find(e => e.value === countryCode)
        if (codeFinded) initCode.value = countryCode
        else initCode.value = 'US'
      }
      phoneCode.value = initCode.value
    }

    const onSelectOnsitePaymentMethod = async onsitePaymentMethodId => {
      if (onsitePaymentMethodId === null || onsitePaymentMethodId === '*') {
        showOnsitePayment.value = false
        computedDataParams.value.onsite_payment_method_id = null
        computedDataParams.value.paid = 'N'
      } else {
        computedDataParams.value.onsite_payment_method_id = onsitePaymentMethodId
        computedDataParams.value.paid = 'Y'

        if (props.editMode) {
          computedDataParams.value.onsite_payment_method_name = props.onsitePaymentMethodsOptions.find(
            e => e.id === onsitePaymentMethodId,
          ).name
          emit('markAsPaid', computedDataParams.value)
        }
      }

      // recalculatePayment()
    }

    const onClearContact = () => {
      contactSelectedId.value = null
      showOnsitePayment.value = false

      // computedDataParams.value.final_amount = 0
      computedDataParams.value.id = null
      computedDataParams.value.user_match_id = null
      computedDataParams.value.contact_id = null
      computedDataParams.value.user_app_id = null
      computedDataParams.value.fullname = null
      computedDataParams.value.phone_number = null
      computedDataParams.value.email = null
      computedDataParams.value.paid = 'N'
      computedDataParams.value.payment_medium = null
      computedDataParams.value.onsite_payment_method_id = null
      computedDataParams.value.onsite_payment_method_name = null
      computedDataParams.value.empty = true
      computedDataParams.value.enabled = true
      computedDataParams.value.is_organizer = 'N'
      computedDataParams.value.avatar = null
      computedDataParams.value.edited = false

      recalculatePayment()
    }

    const onDeleteMatchParticipant = async userMatchId => {
      if (userMatchId) {
        const resp = await removeUserMatch(userMatchId)
        if (resp.ok) {
          // success(resp.message)
          onClearContact()
          emit('removeParticipant', props.placeIndex)
        } else {
          // error(resp.message.text)
        }
      } else {
        emit('removeParticipant', props.placeIndex)
      }
    }

    const onShowOnsitePayment = (add = false) => {
      showOnsitePayment.value = true

      if (add) addGuest()
    }

    const initData = () => {
      const countryCode = getCountryCode()
      if (countryCode) {
        const codeFinded = phoneCodeOptions.value.find(e => e.value === countryCode)
        if (codeFinded) initCode.value = countryCode
        else initCode.value = 'US'
      }
      phoneCode.value = initCode.value

      if ((computedEditMode.value || computedInitMode.value) && computedDataParams.value.id) {
        participantsSearched.value.push({
          id: computedDataParams.value.id,
          facility_id: computedDataParams.value.id,
          first_name: null,
          last_name: null,
          fullname: computedDataParams.value.fullname,
          phone_code: computedDataParams.value.phone_code,
          phone_prefix: computedDataParams.value.phone_prefix,
          phone_number: computedDataParams.value.phone_number,
          email: computedDataParams.value.email,
          status: computedDataParams.value.status,
          avatar: computedDataParams.value.avatar || null,
          user_fullname: null,
          user_app_id: null,
        })
      }
      if (!computedDataParams.value.id) participantsSearched.value = []

      showOnsitePayment.value = false
    }

    watch(
      [computedIsOpen],
      () => {
        if (computedIsOpen.value) {
          if (computedDataParams.value.onsite_payment_method_id) {
            showOnsitePayment.value = true
          }
        } else {
          showOnsitePayment.value = false
          participantsSearched.value = []
        }
      },
      { immediate: true },
    )

    watch([computedInitMode], () => {
      if (computedInitMode.value) {
        initData()
      }
    })

    watch([computedRecurringType], () => {
      if (['F', 'flat'].includes(computedRecurringType.value) && props.placeIndex === 0 && typeof (computedDataParams.value.id) === 'string' && computedDataParams.value.id.includes('guest')) {
        onClearContact()
        participantsSearched.value = []
      }
    })

    onMounted(async () => {
      initData()
    })

    return {
      computedDataParams,
      computedEditMode,
      computedIsOpen,
      computedRecurringType,

      participantsSearched,
      contactSelectedId,
      searchQuery,
      searchLoading,

      isContactModalOpen,
      contactSelected,
      contactData,
      formContactDialog,
      autoContact,

      itemsMenu,

      phoneNumber,
      phoneCode,
      searchedUser,
      searchedUserError,
      timer,
      initCode,
      phoneCodeOptions,
      validatePhone,
      searchUser,

      resolveParticipantLabel,
      searchFilter,
      onSelectContact,
      addContact,
      addGuest,
      avatarText,
      onCreateContact,
      onCancelContact,
      onClearContact,
      onAddMatchParticipant,
      onDeleteMatchParticipant,
      recalculatePayment,

      showOnsitePayment,
      onSelectOnsitePaymentMethod,
      onShowOnsitePayment,

      required,
      integerValidator,
      regexValidator,
      charUnique,
      between,
      emailValidator,
      onlyNumberDot,
      onlyNumbers,

      icons: {
        mdiDotsVertical,
        mdiPlusCircleOutline,
        mdiAccountOutline,
        mdiClose,
        mdiMenuDown,
      },
    }
  },
}
</script>
<style>
.v-list-item {
  height: 100% !important;
}
</style>
<style lang="scss">
.participant-label > .v-input__control > .v-input__slot > .v-text-field__slot label {
  left: 0 !important;
}
</style>
