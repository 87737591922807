<template>
  <div
    class="text-xs h-full border-event text-body-1 d-flex flex-column"
    :class="{'pa-2 pl-3': smallWidth, 'px-3 py-1': !smallWidth, 'cursor-default': ['blocked_time', 'blocked'].includes(computedEvent.card_type)}"
    :style=" `background-color: ${resolveEventColor(computedEvent.card_type, computedEvent.paid)}; border-left: solid 5px #FFB400;`"
  >
    <div class="d-flex align-center my-1">
      <v-chip
        v-if="computedEvent.card_type === 'reservation'"
        color="black"
        small
        class="mr-2 text-xs"
        style="text-transform: uppercase;"
      >
        {{ computedEvent.payment_status_str }}
      </v-chip>

      <slot name="share-icon"></slot>

      <v-fab-transition v-if="computedEvent.card_type === 'reservation' && computedEvent.recurring_id">
        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <v-icon
              size="25"
              color="white"
              v-on="on"
            >
              {{ icons.mdiSync }}
            </v-icon>
          </template>
          <span>{{ `${$t('reservations.recurring')} #${computedEvent.recurring_id}` }}</span>
        </v-tooltip>
      </v-fab-transition>

      <v-icon
        v-if="computedEvent.card_type === 'reservation' && computedEvent.include_video !== 'Y'"
        size="25"
        color="black"
        class="ml-1"
      >
        {{ icons.mdiVideoOutline }}
      </v-icon>
    </div>
    <div
      class="text-body-1 font-weight-bold my-auto black--text d-flex align-center"
      style="text-wrap: pretty; line-height: 1.8rem;"
    >
      <v-icon
        v-if="['blocked_time', 'blocked'].includes(computedEvent.card_type)"
        color="black"
        class="mr-2"
      >
        {{ icons.mdiCancel }}
      </v-icon>
      <span v-if="computedEvent.time_mins > 15">
        <span v-if="computedEvent.card_type === 'reservation'">{{ `${computedEvent.contact_name} (${computedEvent.players})` || $t('reservations.per_participant') }}</span>
        <span v-if="computedEvent.card_type === 'blocked_time' && computedEvent.time_mins > 30">{{ $t('fields.blocked_time') }}</span>
        <span v-if="computedEvent.card_type === 'blocked_time' && computedEvent.time_mins <= 30">{{ $t('reservations.blocked') }}</span>
        <span v-if="!['reservation', 'blocked_time'].includes(computedEvent.card_type)">{{ $t('fields.blocked_by_shared_field') }}</span>
      </span>
    </div>
    <div class="d-flex align-center my-1">
      <span class="text-xs black--text tiny-spacing">{{ computedTime }}</span>
    </div>
  </div>
</template>

<script>
import { computed, getCurrentInstance } from '@vue/composition-api'
import useSelectOptions from '@core/utils/useSelectOptions'
import { mdiVideoOutline, mdiSync, mdiCancel } from '@mdi/js'
import { useUtils } from '@core/libs/i18n'
import { formatStdDate } from '@core/utils'

export default {
  name: 'EventTimeline',
  props: {
    event: {
      type: Object,
      default: () => {},
      required: true,
    },
  },
  setup(props) {
    const vm = getCurrentInstance().proxy
    const { configObjFacility } = useSelectOptions()
    const { t } = useUtils()

    const computedEvent = computed(() => {
      if (props.event.event && props.event.event.extendedProps) return props.event.event.extendedProps

      return null
    })
    const smallWidth = computed(() => document.documentElement.clientWidth < 768)
    const computedTime = computed(() => {
      if (computedEvent.value.time_str) {
        const [t1, t2] = computedEvent.value.time_str.split('-')

        return `${formatStdDate(t1, 'time', configObjFacility.value.is12Hour, vm.$i18n.locale)}-${formatStdDate(
          t2,
          'time',
          configObjFacility.value.is12Hour,
          vm.$i18n.locale,
        )}`
      }

      return ''
    })

    const resolveEventColor = (type, paid) => {
      if (['blocked_time', 'blocked'].includes(type)) return '#9f9f9f'
      if (type === 'reservation' && paid === 'Y') return '#13CE66'
      if (type === 'reservation' && paid === 'N') return '#FFFFFF'

      return '#5f5f5f'
    }

    return {
      smallWidth,
      computedTime,
      computedEvent,

      t,
      resolveEventColor,

      icons: {
        mdiVideoOutline,
        mdiSync,
        mdiCancel,
      },
    }
  },
}
</script>

<style lang="scss">
.tiny-spacing {
  letter-spacing: 0.1px !important;
}
</style>
