export const DURATION_OPTIONS = [
  { text: '1 hr', value: 60 },
  { text: '1.5 hrs', value: 90 },
  { text: '2 hrs', value: 120 },
  { text: '2.5 hrs', value: 150 },
  { text: '3 hrs', value: 180 },
  { text: '3.5 hrs', value: 210 },
  { text: '4 hrs', value: 240 },
  { text: '4.5 hrs', value: 270 },
  { text: '5 hrs', value: 300 },
  { text: '5.5 hrs', value: 330 },
  { text: '6 hrs', value: 360 },
]

export const HOURS_MILITARY_OPTIONS = [
  { text: '12:00 AM', value: '00:00', longValue: '00:00:00' },
  { text: '01:00 AM', value: '01:00', longValue: '01:00:00' },
  { text: '02:00 AM', value: '02:00', longValue: '02:00:00' },
  { text: '03:00 AM', value: '03:00', longValue: '03:00:00' },
  { text: '04:00 AM', value: '04:00', longValue: '04:00:00' },
  { text: '05:00 AM', value: '05:00', longValue: '05:00:00' },
  { text: '06:00 AM', value: '06:00', longValue: '06:00:00' },
  { text: '07:00 AM', value: '07:00', longValue: '07:00:00' },
  { text: '08:00 AM', value: '08:00', longValue: '08:00:00' },
  { text: '09:00 AM', value: '09:00', longValue: '09:00:00' },
  { text: '10:00 AM', value: '10:00', longValue: '10:00:00' },
  { text: '11:00 AM', value: '11:00', longValue: '11:00:00' },
  { text: '12:00 PM', value: '12:00', longValue: '12:00:00' },
  { text: '01:00 PM', value: '13:00', longValue: '13:00:00' },
  { text: '02:00 PM', value: '14:00', longValue: '14:00:00' },
  { text: '03:00 PM', value: '15:00', longValue: '15:00:00' },
  { text: '04:00 PM', value: '16:00', longValue: '16:00:00' },
  { text: '05:00 PM', value: '17:00', longValue: '17:00:00' },
  { text: '06:00 PM', value: '18:00', longValue: '18:00:00' },
  { text: '07:00 PM', value: '19:00', longValue: '19:00:00' },
  { text: '08:00 PM', value: '20:00', longValue: '20:00:00' },
  { text: '09:00 PM', value: '21:00', longValue: '21:00:00' },
  { text: '10:00 PM', value: '22:00', longValue: '22:00:00' },
  { text: '11:00 PM', value: '23:00', longValue: '23:00:00' },
]

export const TIME_MILITARY_OPTIONS = [
  { text: '12:00 AM', value: '00:00' },
  { text: '12:15 AM', value: '00:15' },
  { text: '12:30 AM', value: '00:30' },
  { text: '12:45 AM', value: '00:45' },
  { text: '01:00 AM', value: '01:00' },
  { text: '01:15 AM', value: '01:15' },
  { text: '01:30 AM', value: '01:30' },
  { text: '01:45 AM', value: '01:45' },
  { text: '02:00 AM', value: '02:00' },
  { text: '02:15 AM', value: '02:15' },
  { text: '02:30 AM', value: '02:30' },
  { text: '02:45 AM', value: '02:45' },
  { text: '03:00 AM', value: '03:00' },
  { text: '03:15 AM', value: '03:15' },
  { text: '03:30 AM', value: '03:30' },
  { text: '03:45 AM', value: '03:45' },
  { text: '04:00 AM', value: '04:00' },
  { text: '04:15 AM', value: '04:15' },
  { text: '04:30 AM', value: '04:30' },
  { text: '04:45 AM', value: '04:45' },
  { text: '05:00 AM', value: '05:00' },
  { text: '05:15 AM', value: '05:15' },
  { text: '05:30 AM', value: '05:30' },
  { text: '05:45 AM', value: '05:45' },
  { text: '06:00 AM', value: '06:00' },
  { text: '06:15 AM', value: '06:15' },
  { text: '06:30 AM', value: '06:30' },
  { text: '06:45 AM', value: '06:45' },
  { text: '07:00 AM', value: '07:00' },
  { text: '07:15 AM', value: '07:15' },
  { text: '07:30 AM', value: '07:30' },
  { text: '07:45 AM', value: '07:45' },
  { text: '08:00 AM', value: '08:00' },
  { text: '08:15 AM', value: '08:15' },
  { text: '08:30 AM', value: '08:30' },
  { text: '08:45 AM', value: '08:45' },
  { text: '09:00 AM', value: '09:00' },
  { text: '09:15 AM', value: '09:15' },
  { text: '09:30 AM', value: '09:30' },
  { text: '09:45 AM', value: '09:45' },
  { text: '10:00 AM', value: '10:00' },
  { text: '10:15 AM', value: '10:15' },
  { text: '10:30 AM', value: '10:30' },
  { text: '10:45 AM', value: '10:45' },
  { text: '11:00 AM', value: '11:00' },
  { text: '11:15 AM', value: '11:15' },
  { text: '11:30 AM', value: '11:30' },
  { text: '11:45 AM', value: '11:45' },
  { text: '12:00 PM', value: '12:00' },
  { text: '12:15 PM', value: '12:15' },
  { text: '12:30 PM', value: '12:30' },
  { text: '12:45 PM', value: '12:45' },
  { text: '01:00 PM', value: '13:00' },
  { text: '01:15 PM', value: '13:15' },
  { text: '01:30 PM', value: '13:30' },
  { text: '01:45 PM', value: '13:45' },
  { text: '02:00 PM', value: '14:00' },
  { text: '02:15 PM', value: '14:15' },
  { text: '02:30 PM', value: '14:30' },
  { text: '02:45 PM', value: '14:45' },
  { text: '03:00 PM', value: '15:00' },
  { text: '03:15 PM', value: '15:15' },
  { text: '03:30 PM', value: '15:30' },
  { text: '03:45 PM', value: '15:45' },
  { text: '04:00 PM', value: '16:00' },
  { text: '04:15 PM', value: '16:15' },
  { text: '04:30 PM', value: '16:30' },
  { text: '04:45 PM', value: '16:45' },
  { text: '05:00 PM', value: '17:00' },
  { text: '05:15 PM', value: '17:15' },
  { text: '05:30 PM', value: '17:30' },
  { text: '05:45 PM', value: '17:45' },
  { text: '06:00 PM', value: '18:00' },
  { text: '06:15 PM', value: '18:15' },
  { text: '06:30 PM', value: '18:30' },
  { text: '06:45 PM', value: '18:45' },
  { text: '07:00 PM', value: '19:00' },
  { text: '07:15 PM', value: '19:15' },
  { text: '07:30 PM', value: '19:30' },
  { text: '07:45 PM', value: '19:45' },
  { text: '08:00 PM', value: '20:00' },
  { text: '08:15 PM', value: '20:15' },
  { text: '08:30 PM', value: '20:30' },
  { text: '08:45 PM', value: '20:45' },
  { text: '09:00 PM', value: '21:00' },
  { text: '09:15 PM', value: '21:15' },
  { text: '09:30 PM', value: '21:30' },
  { text: '09:45 PM', value: '21:45' },
  { text: '10:00 PM', value: '22:00' },
  { text: '10:15 PM', value: '22:15' },
  { text: '10:30 PM', value: '22:30' },
  { text: '10:45 PM', value: '22:45' },
  { text: '11:00 PM', value: '23:00' },
  { text: '11:15 PM', value: '23:15' },
  { text: '11:30 PM', value: '23:30' },
  { text: '11:45 PM', value: '23:45' },
]
export const TIME_OPTIONS = [
  { text: '05:00 AM', value: '05:00 AM' },
  { text: '05:15 AM', value: '05:15 AM' },
  { text: '05:30 AM', value: '05:30 AM' },
  { text: '05:45 AM', value: '05:45 AM' },
  { text: '06:00 AM', value: '06:00 AM' },
  { text: '06:15 AM', value: '06:15 AM' },
  { text: '06:30 AM', value: '06:30 AM' },
  { text: '06:45 AM', value: '06:45 AM' },
  { text: '07:00 AM', value: '07:00 AM' },
  { text: '07:15 AM', value: '07:15 AM' },
  { text: '07:30 AM', value: '07:30 AM' },
  { text: '07:45 AM', value: '07:45 AM' },
  { text: '08:00 AM', value: '08:00 AM' },
  { text: '08:15 AM', value: '08:15 AM' },
  { text: '08:30 AM', value: '08:30 AM' },
  { text: '08:45 AM', value: '08:45 AM' },
  { text: '09:00 AM', value: '09:00 AM' },
  { text: '09:15 AM', value: '09:15 AM' },
  { text: '09:30 AM', value: '09:30 AM' },
  { text: '09:45 AM', value: '09:45 AM' },
  { text: '10:00 AM', value: '10:00 AM' },
  { text: '10:15 AM', value: '10:15 AM' },
  { text: '10:30 AM', value: '10:30 AM' },
  { text: '10:45 AM', value: '10:45 AM' },
  { text: '11:00 AM', value: '11:00 AM' },
  { text: '11:15 AM', value: '11:15 AM' },
  { text: '11:30 AM', value: '11:30 AM' },
  { text: '11:45 AM', value: '11:45 AM' },
  { text: '12:00 PM', value: '12:00 PM' },
  { text: '12:15 PM', value: '12:15 PM' },
  { text: '12:30 PM', value: '12:30 PM' },
  { text: '12:45 PM', value: '12:45 PM' },
  { text: '01:00 PM', value: '01:00 PM' },
  { text: '01:15 PM', value: '01:15 PM' },
  { text: '01:30 PM', value: '01:30 PM' },
  { text: '01:45 PM', value: '01:45 PM' },
  { text: '02:00 PM', value: '02:00 PM' },
  { text: '02:15 PM', value: '02:15 PM' },
  { text: '02:30 PM', value: '02:30 PM' },
  { text: '02:45 PM', value: '02:45 PM' },
  { text: '03:00 PM', value: '03:00 PM' },
  { text: '03:15 PM', value: '03:15 PM' },
  { text: '03:30 PM', value: '03:30 PM' },
  { text: '03:45 PM', value: '03:45 PM' },
  { text: '04:00 PM', value: '04:00 PM' },
  { text: '04:15 PM', value: '04:15 PM' },
  { text: '04:30 PM', value: '04:30 PM' },
  { text: '04:45 PM', value: '04:45 PM' },
  { text: '05:00 PM', value: '05:00 PM' },
  { text: '05:15 PM', value: '05:15 PM' },
  { text: '05:30 PM', value: '05:30 PM' },
  { text: '05:45 PM', value: '05:45 PM' },
  { text: '06:00 PM', value: '06:00 PM' },
  { text: '06:15 PM', value: '06:15 PM' },
  { text: '06:30 PM', value: '06:30 PM' },
  { text: '06:45 PM', value: '06:45 PM' },
  { text: '07:00 PM', value: '07:00 PM' },
  { text: '07:15 PM', value: '07:15 PM' },
  { text: '07:30 PM', value: '07:30 PM' },
  { text: '07:45 PM', value: '07:45 PM' },
  { text: '08:00 PM', value: '08:00 PM' },
  { text: '08:15 PM', value: '08:15 PM' },
  { text: '08:30 PM', value: '08:30 PM' },
  { text: '08:45 PM', value: '08:45 PM' },
  { text: '09:00 PM', value: '09:00 PM' },
  { text: '09:15 PM', value: '09:15 PM' },
  { text: '09:30 PM', value: '09:30 PM' },
  { text: '09:45 PM', value: '09:45 PM' },
  { text: '10:00 PM', value: '10:00 PM' },
  { text: '10:15 PM', value: '10:15 PM' },
  { text: '10:30 PM', value: '10:30 PM' },
  { text: '10:45 PM', value: '10:45 PM' },
  { text: '11:00 PM', value: '11:00 PM' },
  { text: '11:15 PM', value: '11:15 PM' },
  { text: '11:30 PM', value: '11:30 PM' },
  { text: '11:45 PM', value: '11:45 PM' },
  { text: '12:00 AM', value: '12:00 AM' },
  { text: '12:15 AM', value: '12:15 AM' },
  { text: '12:30 AM', value: '12:30 AM' },
  { text: '12:45 AM', value: '12:45 AM' },
  { text: '01:00 AM', value: '01:00 AM' },
  { text: '01:15 AM', value: '01:15 AM' },
  { text: '01:30 AM', value: '01:30 AM' },
  { text: '01:45 AM', value: '01:45 AM' },
  { text: '02:00 AM', value: '02:00 AM' },
  { text: '02:15 AM', value: '02:15 AM' },
  { text: '02:30 AM', value: '02:30 AM' },
  { text: '02:45 AM', value: '02:45 AM' },
  { text: '03:00 AM', value: '03:00 AM' },
  { text: '03:15 AM', value: '03:15 AM' },
  { text: '03:30 AM', value: '03:30 AM' },
  { text: '03:45 AM', value: '03:45 AM' },
  { text: '04:00 AM', value: '04:00 AM' },
  { text: '04:15 AM', value: '04:15 AM' },
  { text: '04:30 AM', value: '04:30 AM' },
  { text: '04:45 AM', value: '04:45 AM' },
]

export const convertHour12To24 = timeString => {
  // Validate input format
  const regex = /(\d{1,2}):(\d{2}) (AM|PM)/
  const match = regex.exec(timeString)
  if (!match) {
    throw new Error('Invalid 12-hour time format')
  }

  // Extract time components
  let hours = parseInt(match[1], 10)
  const minutes = parseInt(match[2], 10)
  const isAM = match[3] === 'AM'

  // Convert AM/PM to 24-hour format
  if (isAM && hours === 12) {
    hours = 0
  } else if (!isAM && hours !== 12) {
    hours += 12
  }

  // Construct 24-hour time string
  const convertedTime = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`

  return convertedTime
}

export const convertHour24To12 = timeString => {
  // Extraer horas y minutos
  const hours = parseInt(timeString.substring(0, 2), 10)
  const minutes = timeString.substring(3)

  // Convertir horas a formato 12 horas
  let hour12 = hours
  if (hours > 12) {
    hour12 -= 12
  } else if (hours === 0) hour12 = 12

  // Determinar AM o PM
  let mid = 'AM'
  if (hours >= 12) {
    mid = 'PM'
  }

  // Formatear la hora de salida
  const horaSalida = `${hour12.toString().padStart(2, '0')}:${minutes} ${mid}`

  return horaSalida
}

export const formatHH = hh => {
  const formatTime = hh.split(':')
  if (formatTime[0] < 10) {
    formatTime[0] = `0${formatTime[0]}`
  }

  return formatTime.join(':')
}

export const sumMinutesOptions = (start, end) => {
  const startTime = new Date(`1970-01-01 ${start}`)
  let endTime
  if (end <= start) endTime = new Date(`1970-01-02 ${end}`)
  else {
    endTime = new Date(`1970-01-01 ${end}`)
  }

  const times = []
  while (startTime < endTime) {
    const hour12 = startTime.toLocaleTimeString('es-MX', { hour: 'numeric', minute: 'numeric', hour12: true })
    const hour24 = startTime.toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric' })

    times.push({
      text: formatHH(hour12),
      value: formatHH(hour24),
      hour24: convertHour12To24(formatHH(hour24)),
    })

    startTime.setMinutes(startTime.getMinutes() + 15)
  }

  return times
}

export const sumOffsetHours = (dd, hh, offsetHours = 0) => {
  const dateHour = new Date(`${dd}T${hh}`)
  dateHour.setHours(dateHour.getHours() + offsetHours)
  const hour = dateHour.toLocaleTimeString('en-US', {
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
  })
  const militaryHour = dateHour.toTimeString().split(' ')[0]

  return {
    hour,
    militaryHour,
  }
}

export const customPadStart = (str, targetLength, padString) => {
  // eslint-disable-next-line no-param-reassign
  padString = padString || ' '
  // eslint-disable-next-line no-bitwise, no-param-reassign
  targetLength >>= 0 // Convertir targetLength a entero
  // eslint-disable-next-line no-param-reassign
  str = str || '' // Si str es undefined o null, convertirlo a una cadena vacía
  while (str.length < targetLength) {
    // eslint-disable-next-line no-param-reassign
    str = padString + str
  }

  return str
}
